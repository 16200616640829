@tailwind base;
@tailwind components;
@tailwind utilities;

*, *::before, *::after {
    box-sizing: border-box;
    border: 0 solid transparent;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}