.container {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    z-index: 1;
}

.sbl-circ {
    height: 48px;
    width: 48px;
    color: #2392ff;
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite; }

@keyframes rotate {
    0% {
        transform: rotate(0); }
    100% {
        transform: rotate(360deg); }
}